@import "variables";
@import "framework/framework";
@import '../../node_modules/magnific-popup/dist/magnific-popup';

html {
	background: $color-black url('../img/bg.png') no-repeat center top;
	background-size: cover;
	font-family: "Helvetica Neue LT W05_75 Bold";
	font-size: 14px;
	font-weight: normal;
	line-height: 1;
	overflow-x: hidden;
	text-align: center;
	text-transform: uppercase;
}

body {
	color: $color-white;
}

a {
	color: $color-white;

	@include hover-focus {
		color: $color-white;
	}
}

header, main {
	.container {
		max-width: 600px;
	}
}

header {
	padding: rem(50) 0 rem(40) 0;

	@include phone-down {
		padding: rem(20) 0 rem(50) 0;
	}

	h1 {
		font-family: "Helvetica Neue LT W05_76 Bd It";
		font-size: rem(40);
		font-weight: normal;
		margin: rem(40) 0 0 0;

		> img {
			margin: 0 auto rem(40) auto;
			width: rem(80);
		}

		br {
			display: none;
		}

		@include phone-down {
			font-size: rem(30);
			br {
				display: block;
			}
		}
	}

	h2 {
		font-family: "Helvetica Neue LT W05_76 Bd It";
		font-size: rem(60);
		font-weight: normal;
		margin: rem(30) 0;

		@include phone-down {
			font-size: rem(34);
		}
	}

	h3 {
		font-family: "Helvetica Neue LT W05_76 Bd It";
		font-size: rem(40);
		font-weight: normal;
		margin: 0;

		@include phone-down {
			font-size: rem(30);
		}
	}
}

main {
	h4 {
		font-family: "Helvetica Neue LT W05_76 Bd It";
		font-size: rem(30);
		font-weight: normal;
		margin: 0 0 rem(30) 0;

		@include phone-down {
			font-size: rem(25);
		}
	}

	.follow-links {
		align-items: center;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		list-style: none;
		margin: 0 auto rem(20) auto;
		max-width: 450px;
		padding: 0;

		@include phone-down {
			flex-wrap: wrap;
			margin-bottom: rem(10);
			max-width: 300px;
		}

		> li {
			flex: 0 0 50%;
			padding: 0 10px;

			@include phone-down {
				flex: 0 0 100%;
				padding: 0;

				+ li {
					margin-top: rem(5);
				}
			}

			> a {
				align-items: center;
				background: #f7f7f7;
				border: 2px solid #7c7c7c;
				border-radius: rem(25);
				color: #000;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				font-family: 'Lato', sans-serif;
				font-size: rem(14);
				font-weight: 700;
				height: rem(35);
				justify-content: center;
				margin: 0 auto;
				padding: rem(3);
				text-align: center;
				text-transform: none;

				> span {
					display: inline-block;
					flex: 0 0 auto;
					margin-right: rem(5);
					vertical-align: middle;
				}

				> img {
					display: inline-block;
					flex: 0 0 auto;
					height: rem(17);
					vertical-align: middle;
					width: auto;
				}

				&.spotify {
					background: #1bbe55;
					border-color: #1bbe55;
					color: #fff;

					> svg {
						height: rem(20);
					}
				}

				@include hover-focus {
					opacity: 0.8;
				}
			}
		}
	}

	.disclaimer {
		font-size: rem(8);
		margin: 0;

		@include phone-down {
			margin: 0 auto;
			max-width: 300px;
		}
	}
}

footer {
	padding: rem(60) 0 rem(10) 0;

	@include phone-down {
		padding-top: rem(45);
	}

	.follow {
		margin: 0 0 rem(50);

		@include phone-down {
			margin-bottom: rem(40);
		}

		.follow-title {
			font-family: "Helvetica Neue LT W05_76 Bd It";
			font-size: rem(17);
			letter-spacing: letter-spacing(-20);
			line-height: line-height(21, 17);
			margin-bottom: rem(25);
		}

		.socials {
			align-items: flex-end;
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: center;

			@include phone-down {
				display: block;
			}

			> div {
				&:first-child {
					ul {
						list-style: none;
						margin: 0;
						padding: 0;

						> li {
							display: inline-block;
							font-size: rem(15);
							vertical-align: middle;

							+ li {
								margin-left: rem(10);
							}
						}
					}
				}

				&:last-child {

					@include phone-down {
						margin-top: rem(10);
					}

					form {
						align-items: flex-end;
						display: flex;
						flex-direction: row;
						flex-wrap: nowrap;
						justify-content: flex-end;

						@include phone-down {
							align-content: center;
							justify-content: center;
							margin: 0 auto;
							max-width: 220px;
						}

						.thanks {
							flex: 0 0 100%;
							font-size: rem(12);
							padding: 0 10px;
							text-align: center;
						}

						input {
							background: none;
							border: 0;
							border-bottom: 2px solid $color-white;
							color: $color-white;
							flex: 1 1 auto;
							font-size: rem(12);
							line-height: 1;
							margin: 0 rem(10) 0 rem(20);
							padding: 0 0 rem(3) 0;
							width: rem(185);

							@include phone-down {
								margin-left: 0;
							}

							&:focus {
								background: none;
								border-color: transparent;
								outline: 0;
							}
						}

						button {
							background: none;
							border: 1px solid $color-white;
							color: $color-white;
							flex: 0 0 auto;
							font-family: "Helvetica Neue LT W05_76 Bd It";
							font-size: rem(12);
							font-weight: normal;
							line-height: 1;
							opacity: 1;
							padding: rem(6) rem(8);
							text-transform: uppercase;

							@include hover-focus {
								opacity: 0.8;
								outline: 0;
							}
						}
					}
				}
			}
		}
	}

	.logos {
		margin-bottom: rem(5);

		a {
			display: inline-block;
			opacity: 1;
			vertical-align: middle;

			@include hover-focus {
				opacity: 0.8;
			}

			> svg {
				height: 10px;
				width: auto;
			}
		}
	}

	.copyright {
		font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
		font-size: rem(8);
		letter-spacing: letter-spacing(200);
		line-height: line-height(10, 8);
		text-align: center;
		text-indent: text-indent(200);

		a {
			@include hover-focus {
				text-decoration: underline;
			}
		}
	}
}

.popup {
	background: $color-black;
	color: $color-white;
	margin: 20px auto;
	max-width: 500px;
	padding: rem(20);
	position: relative;
	text-align: center;
	width: auto;

	form {
		label {
			display: block;
			font-size: inherit;
			font-weight: inherit;
			line-height: 1;
			margin-bottom: rem(15);
		}

		input {
			-webkit-appearance: none;
			background: #000;
			border: 2px solid #fff;
			border-radius: 0;
			color: #fff;
			display: block;
			font-size: inherit;
			font-weight: inherit;
			line-height: 1;
			margin-bottom: rem(15);
			padding: rem(5);
			width: 100%;
		}

		button {
			background: none;
			border: 1px solid $color-white;
			color: $color-white;
			flex: 0 0 auto;
			font-family: "Helvetica Neue LT W05_76 Bd It";
			font-size: rem(16);
			font-weight: normal;
			line-height: 1;
			opacity: 1;
			padding: rem(6) rem(8);
			text-transform: uppercase;

			@include hover-focus {
				opacity: 0.8;
				outline: 0;
			}
		}
	}
}

.mfp-close {
	color: $color-white !important;
	opacity: 1 !important;
}